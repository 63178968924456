
import { useParams } from "react-router-dom";
import "./TripDetails.css";
import LandingNav from "../../Landing/Landing-Nav/LandingNav";
import { domesticTrips, internationalTrips } from "../../Landing/PackagesCarousel/PackagesCarousel";

function TripDetails() {
  const { path } = useParams();

  const WhatsAppNumber = "9539771777"; // Add the phone number here if available

  const trip = [...domesticTrips, ...internationalTrips].find((t) => t.path === path);

  if (!trip) return <h2 className="trip-not-found">Trip Not Found</h2>;

  const handleWhatsApp = () => {
    if (WhatsAppNumber) {
      const formattedPhoneNumber = WhatsAppNumber.replace(/\s+/g, '');
      const message = encodeURIComponent("Hello! I am contacting you regarding your trip.");
      const whatsappUrl = `https://wa.me/${formattedPhoneNumber}?text=${message}`;
      window.open(whatsappUrl, '_blank');
    } else {
      console.warn("WhatsApp phone number not available");
    }
  };

  return (
    <>
      <LandingNav />
      <div className="trip-details-container">
        {/* Header Section */}
        <div className="trip-header">
          <h2 className="trip-title">{trip.TripTitle}</h2>
          <p className="trip-location">{trip.TripLocations}</p>
        </div>

        {/* Image & Overview Section */}
        <div className="trip-overview">
          <div className="trip-details-image">
            <img src={trip.TripFile} alt={trip.TripTitle} className="trip-img" />
          </div>

          <div className="trip-info">
            <p><strong>📅 Duration:</strong> {trip.TripDuration}</p>
            <p><strong>✈️ Flights:</strong> {trip.Flights}</p>
            <p><strong>🏨 Hotel Type:</strong> {trip.HotelsTtype}</p>
            <p><strong>🛏️ Stay Details:</strong> {trip.HotelsStay} + {trip.AdditionalStay}</p>
          </div>
        </div>

        {/* Food & Inclusions Section */}
        <div className="trip-columns">
          <div className="trip-card">
            <h3>🎭 Activities</h3>
            <ul>
              {trip.Activities.map((activity, index) => (
                <li key={index}>✔️ {activity}</li>
              ))}
            </ul>
          </div>
          <div className="trip-card">
            <h3>🍽️ Food</h3>
            <ul>
              {trip.Food.map((item, index) => (
                <li key={index}>✔️ {item}</li>
              ))}
            </ul>
          </div>

          <div className="trip-card">
            <h3>✔️ Inclusions</h3>
            <ul>
              {trip.Inclusions.map((item, index) => (
                <li key={index}>✔️ {item}</li>
              ))}
            </ul>
          </div>
        </div>

        {/* Call to Action */}
        <div className="trip-footer">
          <button className="button_04" onClick={handleWhatsApp}>Book Now</button>
        </div>
      </div>
    </>
  );
}

export default TripDetails;
