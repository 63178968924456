import React from 'react'
import LandingNav from '../Landing-Nav/LandingNav'

function About() {
    return (
        <div>
            < LandingNav />


            About



        </div>
    )
}

export default About