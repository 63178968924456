import React from 'react'
import LandingNav from '../Landing-Nav/LandingNav'

function BookNow() {
  return (
    <div>
        <LandingNav/>
        BookNow
        
    </div>
  )
}

export default BookNow