import React from 'react'
import LandingNav from '../Landing-Nav/LandingNav'

function WhyUs() {
    return (
        <div>
            <LandingNav />
            WhyUs
        </div>
    )
}

export default WhyUs