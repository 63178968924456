import React from 'react'
import LandingNav from '../Landing-Nav/LandingNav'

function Contact() {
  return (
    <div>
        <LandingNav/>
        Contact

    </div>
  )
}

export default Contact