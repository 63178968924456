import React from 'react'
import LandingNav from '../Landing-Nav/LandingNav'

function Otherfacilities() {
  return (
    <div>
        <LandingNav/>
        
        Otherfacilities
        
        </div>
  )
}

export default Otherfacilities
