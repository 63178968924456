
import React, { useEffect, useState } from 'react';
import LandingNav from '../Landing-Nav/LandingNav';
import './landing.css';
// import companyimg from '../../Assets/my-yathra/company-img.jpeg';
import companyimg from '../../Assets/my-yathra/WhatsApp Image 2025-02-09 at 10.27.59 PM (1).jpeg';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import instagram from '../../Assets/my-yathra/icons8-instagram-35.png'
import FaceBooklogo from '../../Assets/my-yathra/icons8-facebook-35.png'
import logo from '../../Assets/my-yathra/Artboard 26@4x-8 (1).png';
import whatsapp from '../../Assets/my-yathra/icons8-whatsapp-35.png'
import location from '../../Assets/my-yathra/icons8-location-35 (2).png'
import { GetTrips } from '../../../Api/Trips';
import Loading from '../../Loading/Loading';
import InquiryBooking from '../Inquiry-book/InquiryBooking';
import PackagesCarousel from '../PackagesCarousel/PackagesCarousel';



function Landing() {

  const [allTrips, setAllTrips] = useState([]);
  const [domesticTrips, setDomesticTrips] = useState([]);
  const [internationalTrips, setInternationalTrips] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [errors, setErrors] = useState(null);

  const FaceBook = "https://www.facebook.com/myyathra.in/";
  const Instagram = "https://www.instagram.com/myyathra.in/?hl=en";
  const WhatsAppNumber = "9539771777"; // Add the phone number here if available
  const LocationURL = "https://maps.app.goo.gl/vadmQUGPhzJgrD916/";
  const Address = "Second floor, JACOB'S DD MALL, Mahatma Gandhi Rd, opposite CENTRAL SQUARE MALL, Shenoys, Kochi, Ernakulam, Kerala 682035";
  const CompanyName = "my yathra";
  const About = "To make some beautiful moments in life memorable , as one aiming you there will always be MY YATHRA by your side . Travel makes life beautiful . To directly witness the scenic beauty of kerala , The multicultural diversity that india has to offer and the world with your eyes and not just the things you hear about along with your FRIENDS , FAMILY and LOVED ONES ones to create the happy and the enjoyable moments in your memory lifelong ..."
  const mailId = "office@myyathra.in"; // Add the phone number here if available

  const handleFaceBook = () => {
    if (FaceBook) {
      window.open(FaceBook, '_blank');
    } else {
      console.warn("Facebook URL not available");
    }
  };

  const handleInstagram = () => {
    if (Instagram) {
      window.open(Instagram, '_blank');
    } else {
      console.warn("Instagram URL not available");
    }
  };

  const handleLocation = () => {
    window.open(LocationURL, '_blank');
  };

  const handleWhatsApp = () => {
    if (WhatsAppNumber) {
      const formattedPhoneNumber = WhatsAppNumber.replace(/\s+/g, '');
      const message = encodeURIComponent("Hello! I am contacting you regarding your trip.");
      const whatsappUrl = `https://wa.me/${formattedPhoneNumber}?text=${message}`;
      window.open(whatsappUrl, '_blank');
    } else {
      console.warn("WhatsApp phone number not available");
    }
  };



  // // trip details
  // useEffect(() => {
  //   const fetchTrips = async () => {
  //     try {
  //       const tripData = await GetTrips();
  //       setAllTrips(tripData);
  //       const domestic = tripData?.filter(trip => trip?.TripType === 'domestic');
  //       const international = tripData?.filter(trip => trip?.TripType === 'international');
  //       setDomesticTrips(domestic);
  //       setInternationalTrips(international);

  //     }catch (error) {
  //       console.error("Error fetching notifications:", error);


  //     }
  //     finally {
  //       setLoading(false);
  //     }
  //   }
  //   fetchTrips();
  // }, [])
  // if (loading) {
  //   return <Loading />;
  // }

  return (
    <div className='landing-page' >
      <LandingNav />

      <div className="scrolling-text-container">
        <div className="scrolling-text">Welcome To My Yathra  .. our brand is our promise</div>
      </div>

      <div className='Trip-main'>
        {/* company Image and text */}
        <div class="CompanyMainImg-container">
          <img src={companyimg} alt="not found" class="CompanyMainImg-image" />
          <div class="CompanyMainImg-overlay"></div>
          {/* <div class="CompanyMainImg-text-1">Enjoy Your Vacation With Us</div> */}
          {/* <div class="CompanyMainImg-text-2">I have found out that there ain't no surer way to find out whether you like people or hate them than to travel with them</div> */}
        </div>

        {/* Pass trips data to PackagesCarousel */}
        <PackagesCarousel
          domesticTrips={domesticTrips}
          internationalTrips={internationalTrips}
        />

        {/* Trip carousal and inquiry */}
        <InquiryBooking />

        {/* about us */}
        <div className='aboutus-landing'>
          <h3>ABOUT US</h3>
          <div>{About}</div>
        </div>

        {/* bottom nav */}
        <div className='bottomlanding'>
          <div className='bottomlanding-1'>
            <div className='first'>
              <div className='companydetails'>
                <img src={logo} alt="" />
                {/* <h5><strong> {CompanyName}</strong></h5> */}
                {/* <h5><strong> {CompanyName}</strong></h5> */}
                <h4><strong>Our brand is our promise</strong></h4>


              </div>
              {/* <h4><strong>our brand is our promise</strong></h4> */}
            </div>
            <div className='Second'>
              <h5><strong>Address :</strong> </h5>
              <h5><strong>{CompanyName}</strong></h5>
              <div>{Address}</div>
            </div>


            <div className='Third'>
              <h5><strong>Contact :</strong></h5>
              <div>
                <div>{WhatsAppNumber}</div>
                <div>{mailId}</div>
              </div>
            </div>
          </div>

          <div className='bottomlanding-2'>
            <img src={location} alt="location" title='Location' onClick={handleLocation} />
            <img src={whatsapp} alt="linkedin" title='whatsapp ' onClick={handleWhatsApp} />
            <img src={instagram} alt="linkedin" title='instagram' onClick={handleInstagram} />
            <img src={FaceBooklogo} alt="website" title='FaceBook' onClick={handleFaceBook} />
          </div>

        </div>

      </div>

    </div>
  );
}

export default Landing;
